import React from 'react'
import { OperatorText, DeviceData, PanelProps, DeviceAlarm } from './types'
import {
	tokens, Option, Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader,
	DataGridHeaderCell, DataGridRow, Dropdown, Input, Label, OnSelectionChangeData,
	TableCellLayout, TableColumnDefinition, createTableColumn, makeStyles, shorthands
} from "@fluentui/react-components";
import { Drawer } from '@fluentui/react-components/unstable'
import Grid from '@mui/joy/Grid'
import moment from 'moment'
import { ShareScreenStop24Filled } from '@fluentui/react-icons'
import { callApi } from './utils/api';


export interface AlarmPanelProps extends PanelProps<DeviceAlarm> {

}

const colsDeviceData: TableColumnDefinition<ExpandedDeviceData>[] = [
	createTableColumn<ExpandedDeviceData>({
		columnId: "Name",
		compare: (a, b) => {
			return a.Name.localeCompare(b.Name);
		},
		renderHeaderCell: () => {
			return "Field";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.Name}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<ExpandedDeviceData>({
		columnId: "Value",
		compare: (a, b) => {
			return a.Value.localeCompare(b.Value);
		},
		renderHeaderCell: () => {
			return "Value";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.Value}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<ExpandedDeviceData>({
		columnId: "CreatedAt",
		compare: (a, b) => {
			return moment(a.CreatedAt).diff(b.CreatedAt);
		},
		renderHeaderCell: () => {
			return "Time";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{moment(item.CreatedAt).format('YYYY-MM-DD h:mm:ssa')}<br />
					{moment(item.CreatedAt).calendar()}
				</TableCellLayout>
			);
		},
	}),
]


const useStyles = makeStyles({
	root: {
		// Stack the label above the field
		display: "flex",
		flexDirection: "column",
		// Use 2px gap below the label (per the design system)
		...shorthands.gap("2px"),
		// Prevent the example from taking the full width of the page (optional)
		//maxWidth: "100%",
		fontSize: '10pt',
		marginBottom: '12px'
	},
	panels: {
		...shorthands.padding(0, "10px"),
		"& th": {
			textAlign: "left",
			...shorthands.padding(0, "10px", 0, 0),
		},
	},
	propsTable: {
		"& td:first-child": {
			fontWeight: tokens.fontWeightSemibold,
		},
		"& td": {
			...shorthands.padding(0, "30px", 0, 0),
		},
	},
});

interface ExpandedDeviceData {
	OrgId: string
	DeviceId: string
	Data?: object
	CreatedAt: string
	Name: string
	Value: string
}

const AlarmPanel: React.FC<AlarmPanelProps> = (props) => {
	const alarm = props.selectedObject
	const [theAlarm, setTheAlarm] = React.useState(alarm)
	const [deviceDataList, setDeviceDataList] = React.useState<ExpandedDeviceData[]>([])

	const getDeviceData = async (DeviceId: string) => {
		const res = await callApi(`devices/${DeviceId}/data`)
		const dd: ExpandedDeviceData[] = await res.json() as unknown as ExpandedDeviceData[]

		const history: ExpandedDeviceData[] = []
		dd.forEach(ddr => {
			if (ddr.Data) {
				for (const [key, value] of Object.entries(ddr.Data)) {
					if (props.selectedObject?.AlarmName === key)		// store only data for this field
						history.push({ CreatedAt: ddr.CreatedAt.toString(), DeviceId: ddr.DeviceId, OrgId: ddr.OrgId, Name: key, Value: value })
				}
			}
		})

		setDeviceDataList(history)
	}

	const refresh = () => {
		if (props.selectedObject?.DeviceId)
			getDeviceData(props.selectedObject.DeviceId)
	}

	React.useEffect(() => {
		refresh()
	}, [])

	React.useEffect(() => {
		setTheAlarm(props.selectedObject)
		if (props.selectedObject?.DeviceId)
			getDeviceData(props.selectedObject.DeviceId)
	}, [props])

	const onOperatorSelect = (optionValue: string | undefined) => {
		console.log("optionValue", optionValue)
		setTheAlarm({ ...theAlarm, Operator: optionValue } as DeviceAlarm)
	}

	const onSelectChange = (data: OnSelectionChangeData) => {
		/*	const newSelObId = data.selectedItems.size ? data.selectedItems.values().next().value : ''
			let newSelOb = findObject(objectList, newSelObId)
			setSelectedObject(newSelOb)
			setDrawerOpen(!!newSelOb)*/
	}

	const styles = useStyles();

	const History: React.FC = () => {
		return (<DataGrid
			columns={colsDeviceData}
			items={deviceDataList}
			getRowId={item => item.createdAt}
			onSelectionChange={(e, data) => { onSelectChange(data) }}
			sortable
			//selectionMode="single"
			style={{ fontSize: '9pt', overflow: "auto", height: "400px" }}
		>
			<DataGridHeader>
				<DataGridRow >
					{({ renderHeaderCell }) => (
						<DataGridHeaderCell><strong>{renderHeaderCell()}</strong></DataGridHeaderCell>
					)}
				</DataGridRow>
			</DataGridHeader>
			<DataGridBody<DeviceData>>
				{({ item, rowId }) => (
					<DataGridRow<DeviceData>
						key={rowId}
					//selectionCell={{ "aria-label": "Select row" }}
					>
						{({ renderCell }) => (
							<DataGridCell>{renderCell(item)}</DataGridCell>
						)}
					</DataGridRow>
				)}
			</DataGridBody>
		</DataGrid>
		)
	}

	return (
		<Drawer open={props.open} position='end' size='medium' modalType='non-modal'>
			<div style={{ padding: '32px', width: '500px' }}>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div><strong>ALARM</strong></div>
					<div><ShareScreenStop24Filled style={{ cursor: "pointer", color: "red" }} onClick={() => props.onClose()} /></div>
				</div>
				<br /><br /><br />

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt', float: 'left' }} htmlFor="orgId">Id</Label>
						</Grid>
						<Grid xs={9}>
							<Label id="orgId" style={{ width: '100%' }}>{theAlarm?.AlarmId || ''}</Label><br />
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }} htmlFor="alarmName">Name</Label>
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="alarmName" value={theAlarm?.AlarmName || ''} onChange={(e, d) => { setTheAlarm({ ...theAlarm, AlarmName: d.value } as DeviceAlarm) }} style={{ width: '100%', marginTop: '-8px' }} />
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }} htmlFor="AlarmDescription">Description</Label>
						</Grid>
						<Grid xs={9}>
							<Input appearance='underline' required id="AlarmDescription" value={theAlarm?.AlarmDescription || ''} onChange={(e, d) => { setTheAlarm({ ...theAlarm, AlarmDescription: d.value } as DeviceAlarm) }} style={{ width: '100%', marginTop: '-8px' }} />
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt', paddingTop: '4px' }} htmlFor="Operator">Trigger when Value</Label>
						</Grid>
						<Grid xs={2}>
							<Dropdown 
								placeholder="Operator" 
								style={{ minWidth: 50, width: '90%', marginTop: '-8px' }} 
								value={OperatorText.find(op => theAlarm?.Operator === op.Operator)?.Value || ''} 
								onOptionSelect={(e, d) => onOperatorSelect(d.optionValue)}>

								{OperatorText.map((op) => (
									<Option key={op.Operator} text={op.Value} value={op.Operator}>
										{op.Value}
									</Option>
								))}
							</Dropdown>
						</Grid>
						<Grid xs={7}>
							<Input appearance='underline' required id="AlarmTarget" value={theAlarm?.Target || ''} onChange={(e, d) => { setTheAlarm({ ...theAlarm, Target: d.value } as DeviceAlarm) }} style={{ width: '100%', marginTop: '-8px' }} />
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }}>Current State</Label>
						</Grid>
						<Grid xs={9}>
							<Label>{theAlarm?.State ? theAlarm?.State : "Unknown"}</Label>
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }}>Alarm Last Fired At</Label>
						</Grid>
						<Grid xs={9}>
							<Label>{theAlarm?.LastFiredAt ? moment(theAlarm?.LastFiredAt).format('YYYY-MM-DD h:mm:ssa') : "never"}</Label>
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }}>Alarm Last Cleared At</Label>
						</Grid>
						<Grid xs={9}>
							<Label>{theAlarm?.LastClearedAt ? moment(theAlarm?.LastClearedAt).format('YYYY-MM-DD h:mm:ssa') : "never"}</Label>
						</Grid>
					</Grid>
				</div>

				<div className={styles.root}>
					<Grid container>
						<Grid xs={3}>
							<Label style={{ fontSize: '8pt' }}>Last Report At</Label>
						</Grid>
						<Grid xs={9}>
							<Label>{theAlarm?.LastSeenAt ? moment(theAlarm?.LastSeenAt).format('YYYY-MM-DD h:mm:ssa') : "never"} <strong style={{ color: 'red' }}>{moment(theAlarm?.LastSeenAt).add(4, 'hours') < moment() ? "(more than 4 hours ago)" : ""}</strong></Label>
						</Grid>
					</Grid>
				</div>

				<div style={{ marginTop: '32px' }}>
					<strong>FIELD HISTORY</strong>
				</div>

				<div className={styles.root}>
					<History />
				</div>

				<div style={{ position: "absolute", bottom: '32px' }}>
					<Button appearance='primary' onClick={() => props.onSave(theAlarm as DeviceAlarm)}>Save</Button>
					&nbsp;&nbsp;
					<Button appearance='secondary' onClick={() => props.onClose()}>Close</Button>
				</div>
			</div>
		</Drawer >
	)
}

export default AlarmPanel
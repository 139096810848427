import React from 'react'
import { GlobalContext } from './App'
import CrudElement from './crud'
import { DeviceAlarm } from './types'
import { TableCellLayout, TableColumnDefinition, createTableColumn } from '@fluentui/react-components'
import AlarmPanel from './alarmPanel'
import callApi from './utils/api'

/*
AlarmId: string;
DeviceId: string;
FieldId: string;
Description: string
Operator: "EQ" | "GT" | "GTE" | "LT" | "LTE" | "NE";
Value: string;
OrgId: string;
State: "Active" | "Clear"
LastClearedAt: number
LastFiredAt: number
CreatedAt: number
UpdatedAt: number
*/

const columns: TableColumnDefinition<DeviceAlarm>[] = [
	createTableColumn<DeviceAlarm>({
		columnId: "AlarmName",
		compare: (a, b) => {
			return a.AlarmName.localeCompare(b.AlarmName);
		},
		renderHeaderCell: () => {
			return "Field";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.AlarmName}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<DeviceAlarm>({
		columnId: "Device",
		compare: (a, b) => {
			return a.DeviceName.localeCompare(b.DeviceName);
		},
		renderHeaderCell: () => {
			return "Device";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.DeviceName}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<DeviceAlarm>({
		columnId: "DeviceType",
		compare: (a, b) => {
			return a.DeviceType.localeCompare(b.DeviceType)
		},
		renderHeaderCell: () => {
			return "DeviceType";
		},

		renderCell: (item) => {
			return item.DeviceType;
		},
	}),
	createTableColumn<DeviceAlarm>({
		columnId: "CurrValue",
		compare: (a, b) => {
			return a.FieldId.localeCompare(b.FieldId)
		},
		renderHeaderCell: () => {
			return "CurrValue";
		},

		renderCell: (item) => {
			return item.CurrValue;
		},
	}),
	createTableColumn<DeviceAlarm>({
		columnId: "Operator",
		compare: (a, b) => {
			return a.Operator.localeCompare(b.Operator)
		},
		renderHeaderCell: () => {
			return "Operator";
		},

		renderCell: (item) => {
			return item.Operator;
		},
	}),
	createTableColumn<DeviceAlarm>({
		columnId: "Target",
		compare: (a, b) => {
			return a.Target.localeCompare(b.Target)
		},
		renderHeaderCell: () => {
			return "Target";
		},

		renderCell: (item) => {
			return item.Target;
		},
	}),
	createTableColumn<DeviceAlarm>({
		columnId: "State",
		compare: (a, b) => {
			return a.State.localeCompare(b.State)
		},
		renderHeaderCell: () => {
			return "State";
		},

		renderCell: (item) => {
			return item.State;
		},
	}),
]

const DeviceAlarmList = () => {
	const { state, setState } = React.useContext(GlobalContext);

	const getDeviceAlarms = () => {
		return callApi(`alarms`).then(res => res.json())
	}

	const filterObject = (ob: DeviceAlarm, filterText: string): boolean => {
		return ob.AlarmName.toLowerCase().includes(filterText) || ob.AlarmDescription.toLowerCase().includes(filterText) || 
				 ob.DeviceType.toLowerCase().includes(filterText) || ob.DeviceName.toLowerCase().includes(filterText)
	}

	const createDeviceAlarm = (location: DeviceAlarm) => {

		console.log("Creating", location)
		return callApi('alarms', 'POST', JSON.stringify({ Name: location.AlarmName, OrgId: state.Org ? state.Org.OrgId : null }))
			.then(res => res.json())
			.then(d => { console.log(d); })
	}

	const saveDeviceAlarm = (location: DeviceAlarm) => {

		console.log("Creating", location)
		return callApi('alarms', 'POST', JSON.stringify({ Name: location.AlarmName, OrgId: state.Org ? state.Org.OrgId : null }))
			.then(res => res.json())
			.then(d => { console.log(d); })
	}


	const selectDeviceAlarm = (location: DeviceAlarm | null) => { }

	const isDeviceAlarmSelected = (): boolean => { return false }

	const findDeviceAlarm = (list: DeviceAlarm[], id: string) => {
		return list.find(a => a.AlarmId === id) || null
	}

	return (
		<div style={{ margin: '32px' }}>
			{CrudElement<DeviceAlarm>("Alarms", "Alarm", getDeviceAlarms, filterObject, (o: DeviceAlarm) => o.AlarmId, createDeviceAlarm, selectDeviceAlarm, saveDeviceAlarm, isDeviceAlarmSelected, columns, AlarmPanel, findDeviceAlarm)}
		</div>
	)
}

export default DeviceAlarmList;

import React from 'react'
import { DeviceData, DeviceMetadata, MetadataType } from './types'
import { Checkbox } from '@fluentui/react-components'
import moment from 'moment'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import callApi from './utils/api'

const AnnoAxisTemplate = {
	x: new Date('23 Nov 2017').getTime(),
	strokeDashArray: 2,
	borderColor: '#333',
	label: {
		borderColor: '#333',
		borderRadius: 4,
		style: {
			color: '#fff',
			background: '#333',
		},
		text: '',
	}
}

const AnnotationTemplate = {
	x: new Date('18 Dec 2023 14:05').getTime(),
	y: 86.55,
	marker: {
		size: 2,
		fillColor: '#fff',
		strokeColor: 'green',
		radius: 2,
	},
	label: {
		borderColor: '#00C560',
		offsetY: 0,
		borderRadius: 16,
		style: {
			color: '#fff',
			background: '#00C560',
		},
		text: 'Filter Replaced',
	},
	colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']
}

const DeviceDataCharts = () => {
	const [deviceData, setDeviceData] = React.useState<DeviceData[]>([])
	const [metadata, setMetadata] = React.useState<DeviceMetadata[]>([])
	const [selectedFields, setSelectedFields] = React.useState<DeviceMetadata[]>([])
	const [series, setSeries] = React.useState([] as any[])
	const [options, setOptions] = React.useState<ApexOptions>({
		title: { text: 'Device Data over Time' },
		chart: { type: 'line' },
		stroke: { width: 5, curve: 'straight' },
		xaxis: { tickAmount: 10, type: 'datetime', labels: { formatter: function (value: string) { return [moment(value).format('MM/DD/YYYY'), moment(value).format('HH:mm:SS')] } } },
		yaxis: { min: 0, max: 100, labels: { formatter: function (value: number) { return Math.floor(value).toString() } } },
		annotations: {
			yaxis: [],
			xaxis: [],
			points: []
		},
		grid: {
			yaxis: {
				lines: {
					show: true
				}
			},
			row: {
				colors: ['white'],
				opacity: 0.5
			},
			padding: {
				left: 30, // or whatever value that works
				right: 30 // or whatever value that works
			}
		},
		dataLabels: {
			enabled: false,
		},
		markers: {
			size: 5,
		},
		forecastDataPoints: {
			count: 0,
			fillOpacity: 0.5,
			strokeWidth: 3,
			dashArray: 4,
		}
	})

	const getDeviceData = async (DeviceId: string, Metadata: DeviceMetadata[], selFields: DeviceMetadata[]) => {
		const r = await callApi(`devices/${DeviceId}/data`)
		let d = await (r.json())
		//d = d.reverse()

		const ddRows = d.reverse() as unknown as DeviceData[]
		setDeviceData(ddRows)

		const newSeries = selFields.map(md => {
			return {
				name: md.Name,
				data: ddRows.filter(row => !!Object.keys(row.Data).find(key => key === md.Name))
					.map(row => { return { x: new Date(row.CreatedAt), y: ((Object.entries(row.Data).find(entry => entry[0] === md.Name)) || [0, 1])[1] } })
			}
		})
		setSeries(newSeries)

		// Generate Points of Interest from non-number fields

		const points: any[] = [], xaxis: any[] = []
		Metadata.forEach(field => {
			if (field.Type === MetadataType.Enum || field.Type === MetadataType.String) {

				let lastValue: number | string | null = null
				ddRows.forEach(dd => {
					const data = dd.Data as any
					if (data[field.Name] !== undefined) {

						const value = data[field.Name]
						//console.log("values", value, lastValue)
						if (lastValue !== null && value !== lastValue) {
							// Create annotation
							const anno = { ...AnnotationTemplate }
							anno.x = new Date(dd.CreatedAt).getTime()
							anno.y = value
							anno.label.text = `${field.Name} = ${value.toString()}`
							points.push(anno)

							const anno2 = { ...AnnoAxisTemplate, label: { ...AnnoAxisTemplate.label } }
							anno2.x = new Date(dd.CreatedAt).getTime()
							anno2.label.text = field.Description + ': ' + lastValue.toString() + ' ==> ' + value.toString()
							xaxis.push(anno2)
						}
						lastValue = value
					}
				})
			}
		})
		const newOptions = {
			...options,
			annotations: {
				xaxis: xaxis
				//points: points
			}
		}
		setOptions(newOptions)
	}

	const getData = async (DeviceTypeId: string, DeviceId: string) => {
		callApi(`devicetypes/${DeviceTypeId}/metadata`)
			.then(res => res.json())
			.then(res => {
				const metadata = (res as DeviceMetadata[]).filter(md => md.IsTimeSeries)
				setMetadata(metadata);

				const selFields = metadata.filter(md => md.Type === MetadataType.Number).filter(md => md.Name.includes('ilter'))
				if (selectedFields.length === 0)
					setSelectedFields(selFields)		 // just for testing
				getDeviceData(DeviceId, metadata, selFields)
			})
	}

	React.useEffect(() => {
		getData('353c22e4-3e79-4991-9f06-28d6b9731227', '636f025b-fc57-42c4-b219-4f6942646b75')
	}, [selectedFields])

	const setChecked = (meta: DeviceMetadata, checked: boolean | string) => {
		if (checked) {
			setSelectedFields([...selectedFields, meta])
		}
		else {
			setSelectedFields(selectedFields.filter(field => field.FieldId !== meta.FieldId))
		}

	}

	return (
		<div style={{ margin: '32px', width: '95%', minWidth: '1200px', minHeight: '800px' }}>


			{metadata./*filter(meta => meta.Type === MetadataType.Number).*/map(meta => (
				<Checkbox key={meta.FieldId} label={meta.Name} checked={!!selectedFields.find(field => field.FieldId === meta.FieldId)}
					onChange={(ev, data) => setChecked(meta, data.checked)} />
			))}
			<br />
			<br />
			{!!series.length && <ReactApexChart
				series={series}
				options={options}
				width="100%"
				height="100%"
			/>}
		</div>
	)
}

export default DeviceDataCharts

import React from 'react'
import { GlobalContext } from './App'
import CrudElement from './crud'
import { Organization } from './types'
import { Avatar, TableCellLayout, TableColumnDefinition, createTableColumn } from '@fluentui/react-components'
import OrgPanel from './orgPanel'
import { callApi } from './utils/api'


const columns: TableColumnDefinition<Organization>[] = [
	createTableColumn<Organization>({
		columnId: "name",
		compare: (a, b) => {
			return a.Name.localeCompare(b.Name);
		},
		renderHeaderCell: () => {
			return "Name";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout
					media={
						<Avatar
							aria-label={item.Name}
							name={item.Name}
							badge={{ status: 'available' }}
						/>
					}
				>
					{item.Name}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<Organization>({
		columnId: "parent",
		compare: (a, b) => {
			const parNamesA = a.ParentNames || []
			const parNamesB = b.ParentNames || []
			return (parNamesA[parNamesA.length - 1] || a.ParentOrgIds[a.ParentOrgIds.length - 1] || "").localeCompare(parNamesB[parNamesB.length - 1] || b.ParentOrgIds[a.ParentOrgIds.length - 1] || "");
		},
		renderHeaderCell: () => {
			return "Parent";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.ParentNames ? item.ParentNames[item.ParentNames?.length - 1] : item.ParentOrgIds[item.ParentOrgIds.length - 1]}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<Organization>({
		columnId: "lastUpdated",
		compare: (a, b) => {
			return a.UpdatedAt - b.UpdatedAt;
		},
		renderHeaderCell: () => {
			return "Last updated";
		},

		renderCell: (item) => {
			return item.UpdatedAt;
		},
	}),
]

const Org = () => {
	const { state, setState } = React.useContext(GlobalContext);

	const getOrgs = () => {
		const uri = state.Org ? `orgs/${state.Org.OrgId}/orgs` : 'orgs'
		console.log("getting orgs", uri)
		return callApi(uri).then(res => res.json() as unknown as Organization[])
			.then(res => {

				// Fill names for parent orgs -- TODO: this current assumes that all orgs have been loaded in this list
				res.forEach(org => {
					org.ParentNames = org.ParentOrgIds.map(parent => {
						let parName: string = "** Missing Organization Name **"
						res.forEach(org2 => {
							console.log("parent", '{' + parent + '}', "org2.OrgId", '{' + org2.OrgId + '}')
							if (parent === org2.OrgId) {
								parName = org2.Name
							}
						})
						return parName
					})
				})

				return res
			})
	}

	const filterObject = (ob: Organization, filterText: string): boolean => {
		return ob.Name.toLowerCase().includes(filterText)
	}

	const createOrg = (org: Organization) => {

		console.log("Creating", org)
		return callApi('orgs', 'POST', JSON.stringify({ Name: org.Name, ParentOrgIds: org.ParentOrgIds }))
			.then(res => res.json())
			.then(d => { console.log(d); })
	}

	const saveOrg = (org: Organization) => {

		console.log("Saving", org)
		return callApi(`orgs/${org.OrgId}`, 'PUT', JSON.stringify({ OrgId: org.OrgId, Name: org.Name, ParentOrgIds: org.ParentOrgIds }))
			.then(res => res.json())
			.then(d => { console.log(d); })
	}


	const selectOrg = (org: Organization | null) => {
		setState({
			...state,
			Org: org,
		})
	}

	const isOrgSelected = (): boolean => {
		return !!(state.Org)
	}

	const findOrg = (list: Organization[], id: string) => {
		return list.find(a => a.OrgId === id) || null
	}

	return (
		<div style={{ margin: '32px' }}>
			{CrudElement<Organization>("Organizations", "Organization", getOrgs, filterObject, (o: Organization) => o.OrgId, createOrg, selectOrg, saveOrg, isOrgSelected, columns, OrgPanel, findOrg, false)}
		</div>
	)
}

export default Org;

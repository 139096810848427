import React from 'react'
import { GlobalContext } from './App'
import CrudElement from './crud'
import { Location } from './types'
import { TableCellLayout, TableColumnDefinition, createTableColumn } from '@fluentui/react-components'
import LocationPanel from './locationPanel'
import { callApi } from './utils/api'


const columns: TableColumnDefinition<Location>[] = [
	createTableColumn<Location>({
		columnId: "id",
		compare: (a, b) => {
			return a.LocationId.localeCompare(b.LocationId);
		},
		renderHeaderCell: () => {
			return "Id";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.LocationId}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<Location>({
		columnId: "Name",
		compare: (a, b) => {
			return a.Description.localeCompare(b.Description);
		},
		renderHeaderCell: () => {
			return "Name";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.Description}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<Location>({
		columnId: "lastUpdated",
		compare: (a, b) => {
			return a.UpdatedAt - b.UpdatedAt;
		},
		renderHeaderCell: () => {
			return "Last updated";
		},

		renderCell: (item) => {
			return item.UpdatedAt;
		},
	}),
]

const LocationList = () => {
	const { state, setState } = React.useContext(GlobalContext);

	const getLocations = () => {
		if (state.Org)
			return callApi(`orgs/${state.Org.OrgId}/locations`).then(res => res.json())
		else
			return callApi(`locations`).then(res => res.json())
	}

	const filterObject = (ob: Location, filterText: string): boolean => {
		return ob.Description.toLowerCase().includes(filterText)
	}

	const createLocation = (location: Location) => {

		console.log("Creating", location)
		return callApi('locations', 'POST', JSON.stringify({ Name: location.Description, OrgId: state.Org ? state.Org.OrgId : null }))
			.then(res => res.json())
			.then(d => { console.log(d); })
	}

	const saveLocation = (location: Location) => {

		console.log("Creating", location)
		return callApi('locations', 'POST', JSON.stringify({ Name: location.Description, OrgId: state.Org ? state.Org.OrgId : null }))
			.then(res => res.json())
			.then(d => { console.log(d); })
	}


	const selectLocation = (location: Location | null) => {}

	const isLocationSelected = (): boolean => { return false }

	const findLocation = (list: Location[], id: string) => {
		return list.find(a => a.LocationId === id) || null
	}

	return (
		<div style={{ margin: '32px' }}>
			{CrudElement<Location>("Locations", "Location", getLocations, filterObject, (o: Location) => o.LocationId, createLocation, selectLocation, saveLocation, isLocationSelected, columns, LocationPanel, findLocation)}
		</div>
	)
}

export default LocationList;

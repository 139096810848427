import React from 'react'
import { GlobalContext } from './App'
import CrudElement from './crud'
import { App } from './types'
import { Avatar, TableCellLayout, TableColumnDefinition, createTableColumn } from '@fluentui/react-components'
import appPanel from './appPanel'
import callApi from './utils/api'


const columns: TableColumnDefinition<App>[] = [
	/*createTableColumn<App>({
		columnId: "id",
		compare: (a, b) => {
			return a.AppId.localeCompare(b.AppId);
		},
		renderHeaderCell: () => {
			return "Id";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.AppId}
				</TableCellLayout>
			);
		},
	}),*/
	createTableColumn<App>({
		columnId: "Name",
		compare: (a, b) => {
			return a.Name.localeCompare(b.Name);
		},
		renderHeaderCell: () => {
			return "Name";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout
					media={
						<Avatar
							aria-label={item.Name}
							name={item.Name}
							badge={{ status: 'available' }}
						/>
					}
				>
					{item.Name}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<App>({
		columnId: "WebHook",
		compare: (a, b) => {
			return (a.WebHookUri ?? '').localeCompare(b.WebHookUri ?? '');
		},
		renderHeaderCell: () => {
			return "Web Hook";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.WebHookUri}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<App>({
		columnId: "OwningOrg",
		compare: (a, b) => (a.OwnerOrgId ?? '').localeCompare(b.OwnerOrgId ?? ''),
		renderHeaderCell: () => {
			return "Owning Org";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.OwnerOrgId}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<App>({
		columnId: "lastUpdated",
		compare: (a, b) => a.UpdatedAt < b.UpdatedAt ? -1 : 1,
		renderHeaderCell: () => {
			return "Last updated";
		},
		renderCell: (item) => {
			return item.UpdatedAt.toString()
		},
	}),
]

const AppList = () => {
	const {state, setState} = React.useContext(GlobalContext);

	const getApps = () => {
		return callApi('apps').then(res => res.json())
	}


	const filterObject = (ob: App, filterText: string): boolean => {
		return ob.Name.toLowerCase().includes(filterText)
	}

	const createApp = (app: App) => {

		console.log("Creating", app)
		return callApi('apps', 'POST', JSON.stringify({ Name: app.Name, OrgId: state.Org ? state.Org.OrgId : null}))
			.then(res => res.json())
			.then(d => { console.log(d); })
	}

	const saveApp = (app: App) => {

		console.log("Creating", app)
		return callApi('apps', 'PUT', JSON.stringify({ Name: app.Name, OrgId: app.OwnerOrgId, WebHookUri: app.WebHookUri, WebHookSecret: app.WebHookSecret }))
			.then(res => res.json())
			.then(d => { console.log(d); })
	}


	const selectApp = (app: App | null) => {
	}

	const isAppSelected = (): boolean => {
		return false
	}

	const findApp = (list: App[], id: string) => {
		return list.find(a => a.AppId === id) || null
	}

	return (
		<div style={{ margin: '32px' }}>
			{CrudElement<App>("Apps", "App", getApps, filterObject, (o: App) => o.AppId, createApp, selectApp, saveApp, isAppSelected, columns, appPanel, findApp)}
		</div>
	)
}

export default AppList;

import React from 'react'
import { GlobalContext } from './App'
import CrudElement from './crud'
import { Plan } from './types'
import { Avatar, TableCellLayout, TableColumnDefinition, createTableColumn } from '@fluentui/react-components'
import planPanel from './planPanel'
import { callApi } from './utils/api'


const columns: TableColumnDefinition<Plan>[] = [
	createTableColumn<Plan>({
		columnId: "id",
		compare: (a, b) => {
			return a.OrgId.localeCompare(b.OrgId);
		},
		renderHeaderCell: () => {
			return "File";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.OrgId}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<Plan>({
		columnId: "author",
		compare: (a, b) => {
			return a.Name.localeCompare(b.Name);
		},
		renderHeaderCell: () => {
			return "Author";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout
					media={
						<Avatar
							aria-label={item.Name}
							name={item.Name}
							badge={{ status: 'available' }}
						/>
					}
				>
					{item.Name}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<Plan>({
		columnId: "lastUpdated",
		compare: (a, b) => {
			return a.UpdatedAt - b.UpdatedAt;
		},
		renderHeaderCell: () => {
			return "Last updated";
		},

		renderCell: (item) => {
			return item.UpdatedAt;
		},
	}),
]

const PlanList = () => {
	const { state, setState } = React.useContext(GlobalContext);

	const getPlans = () => {
		if (state.Org)
			return callApi(`orgs/${state.Org.OrgId}/plans`).then(res => res.json())
		else
			return callApi('plans').then(res => res.json())
	}

	const filterObject = (ob: Plan, filterText: string): boolean => {
		return ob.Name.toLowerCase().includes(filterText)
	}

	const createPlan = (plan: Plan) => {

		console.log("Creating", plan)
		return callApi('plans', 'POST', JSON.stringify({ Name: plan.Name, OrgId: state.Org ? state.Org.OrgId : null }))
			.then(res => res.json())
			.then(d => { console.log(d); })
	}

	const savePlan = (plan: Plan) => {

		console.log("Creating", plan)
		return callApi('plans', 'POST', JSON.stringify({ Name: plan.Name, OrgId: state.Org ? state.Org.OrgId : null }))
			.then(res => res.json())
			.then(d => { console.log(d); })
	}


	const selectPlan = (plan: Plan | null) => {}

	const isPlanSelected = (): boolean => { return false }

	const findPlan = (list: Plan[], id: string) => {
		return list.find(a => a.PlanId === id) || null
	}

	return (
		<div style={{ margin: '32px' }}>
			{CrudElement<Plan>("Plans", "Plan", getPlans, filterObject, (o: Plan) => o.PlanId, createPlan, selectPlan, savePlan, isPlanSelected, columns, planPanel, findPlan)}
		</div>
	)
}

export default PlanList;

import React from 'react'
import { GlobalContext } from './App'
import CrudElement from './crud'
import { Device, Location } from './types'
import { TableCellLayout, TableColumnDefinition, createTableColumn } from '@fluentui/react-components'
import devicePanel from './devicePanel'
import { callApi } from './utils/api'


const columns: TableColumnDefinition<Device>[] = [
	/*createTableColumn<Device>({
		columnId: "id",
		compare: (a, b) => {
			return a.DeviceId.localeCompare(b.DeviceId);
		},
		renderHeaderCell: () => {
			return "Id";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.DeviceId}
				</TableCellLayout>
			);
		},
	}),*/
	createTableColumn<Device>({
		columnId: "Name",
		compare: (a, b) => {
			return a.Name.localeCompare(b.Name);
		},
		renderHeaderCell: () => {
			return "Name";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.Name}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<Device>({
		columnId: "devTypeName",
		compare: (a, b) => {
			return (a.DeviceTypeName || "").localeCompare(b.DeviceTypeName || "");
		},
		renderHeaderCell: () => {
			return "Device Type";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.DeviceTypeName}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<Device>({
		columnId: "Location",
		compare: (a, b) => {
			return (a.LocationId || "").localeCompare(b.LocationId || "");
		},
		renderHeaderCell: () => {
			return "Location";
		},
		renderCell: (item) => {
			return (
				<TableCellLayout>
					{item.Location ? item.Location : item.LocationId}
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<Device>({
		columnId: "lastUpdated",
		compare: (a, b) => {
			return a.UpdatedAt - b.UpdatedAt;
		},
		renderHeaderCell: () => {
			return "Last updated";
		},

		renderCell: (item) => {
			return item.UpdatedAt;
		},
	}),
]

const DeviceList = () => {
	const { state, setState } = React.useContext(GlobalContext);

	const getDevices = async () => {
		let devices: Device[] = []
		if (state.Org)
			devices = await callApi(`orgs/${state.Org.OrgId}/devices`).then(res => res.json())
		else
			devices = await callApi('devices/full').then(res => res.json())

		// Get locations and fix up location names in device objects
		const locations: Location[] = await callApi('locations').then(res => res.json())
		devices = devices.map(dev => {
			const devLoc = locations.find(loc => loc.LocationId === dev.LocationId)
			return { ...dev, Location: devLoc?.Description }
		})

		return devices
	}

	const filterObject = (ob: Device, filterText: string): boolean => {
		return ob.Name.toLowerCase().includes(filterText)
	}

	const createDevice = (device: Device) => {

		return callApi('devices', 'POST', JSON.stringify({ Name: device.Name, OrgId: device.OrgId }))
			.then(res => res.json())
			.then(d => { console.log(d); })
	}

	const saveDevice = (device: Device) => {

		console.log("Saving device", device)
		return callApi(`devices/${device.DeviceId}`, 'PUT', JSON.stringify({ Name: device.Name, OrgId: device.OrgId, LocationId: device.LocationId }))
			.then(res => res.json())
			.then(d => { console.log(d); })
	}


	const selectDevice = (device: Device | null) => {
		setState({
			...state,
			Device: device
		})
	}

	const isDeviceSelected = (): boolean => {
		return !!(state.Device)
	}

	const findDevice = (list: Device[], id: string) => {
		return list.find(a => a.DeviceId === id) || null
	}

	return (
		<div style={{ margin: '32px' }}>
			{CrudElement<Device>("Devices", "Device", getDevices, filterObject, (o: Device) => o.DeviceId, createDevice, selectDevice, saveDevice, isDeviceSelected, columns, devicePanel, findDevice)}
		</div>
	)
}

export default DeviceList;

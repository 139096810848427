import { API_BASE } from '../env'

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export const callApi = (endpoint: string, method: Method = 'GET', body?: string): Promise<Response> => {

	const options: any = {
		method: method,
		headers: {
			"Content-Type": "application/json",
			"X-ApiToken": "mREFt86zqcWtp69Oif5kw1Sia3h4B9OZFF1ybqVGfmFx7UFCTbHWF80S6bKmXMvm"
		},
	}
	if (body)
		options.body = body

	return fetch(`${API_BASE}/${endpoint}`, options)
}

export default callApi
import React from 'react'
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Home24Regular, Organization24Regular, DeviceEq24Regular, People24Regular, DeviceMeetingRoom24Regular, ChartMultiple24Regular, Location24Regular, AppsList24Regular, Settings24Regular, GroupList24Regular, AppGeneric24Regular, DatabasePerson24Regular, Alert24Regular, Map24Regular } from '@fluentui/react-icons'
import OrgList from './org'
import UserList from './users';
import DeviceList from './devices';
import DeviceTypeList from './deviceTypes';
import PlanList from './plans';
import { FluentProvider, teamsDarkTheme, teamsLightTheme, Avatar, TabList, Tab, SelectTabData, SelectTabEvent, Option, Dropdown, webDarkTheme, webLightTheme } from "@fluentui/react-components";
import { State } from './types';
import { lightTheme, darkTheme } from './theme';
import LocationList from './locations';
import HomeMenu from './home';
import { Settings } from './settings';
import DeviceDataCharts from './deviceData';
import DeviceAlarmList from './alarms';
import DeviceMap from './deviceMap';
import logoLight from './agora-logo-on-black.png'
import logoDark from './agora-logo-on-white.png'
import { AgAuthContext } from "@silverfallscapital/agora-sdk";
import AppList from './apps';

const emptyState: State = { Org: null, User: null, Device: null }
const availableThemes = [
	{ Name: "Default Light", theme: lightTheme, logo: logoLight },
	{ Name: "Default Dark", theme: darkTheme, logo: logoDark },
	{ Name: "Teams Light", theme: teamsLightTheme, logo: logoLight },
	{ Name: "Teams Dark", theme: teamsDarkTheme, logo: logoDark },
	{ Name: "Web Light", theme: webLightTheme, logo: logoLight },
	{ Name: "Web Dark", theme: webDarkTheme, logo: logoDark },
]

export const GlobalContext = React.createContext({ state: emptyState, setState: (state: State) => { } });

interface NavigationProps {
	navigateTo: string
}

const Navigation: React.FC<NavigationProps> = (props) => {

	const navigate = useNavigate()

	React.useEffect(() => {
		if (props.navigateTo) {
			navigate(props.navigateTo);
		}
	}, [props, navigate]);

	return (<React.Fragment></React.Fragment>)
}

interface TabProps {
	value: string
	icon: React.ReactElement
	text: string
}

const SpacedTab = (props: TabProps) => <Tab style={{ padding: '8px 12px' }} value={props.value} icon={props.icon}>{props.text}</Tab>

const App = () => {
	const [globalState, setGlobalState] = React.useState<State>(emptyState)
	const [currentTheme, setCurrentTheme] = React.useState(lightTheme)
	const [navigateTo, setNavigateTo] = React.useState("")
	const [currentThemeName, setCurrentThemeName] = React.useState("Default Light")
	const [currentLogo, setCurrentLogo] = React.useState(logoLight)
	const iconColor = currentTheme.colorBrandForeground2Hover
	const [authContext, setAuthContext] = React.useState<AgAuthContext | null>(null)

	if (null === authContext) {
		const user = new AgAuthContext()
		user.login('olst@outlook.com', '876u4b&8645&y').then(() => {
			setAuthContext(user)
		})
	}

	const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
		setNavigateTo(data.value as string)
	}

	return (
		<FluentProvider theme={currentTheme}>
			<BrowserRouter>
				<GlobalContext.Provider value={{ state: globalState, setState: setGlobalState }}>
					<div style={{ backgroundColor: currentTheme.colorBrandForeground2, color: 'white', margin: 0, padding: '20px', width: '100vw' }}>
						<div style={{ display: "flex", alignItems: 'bottom', float: 'left', fontSize: '12pt' }}>
							<img
								style={{ marginTop: '-4px', marginRight: '64px', marginLeft: '-12px' }}
								height='56px' alt="Agora logo"
								src={currentLogo}
							/>
							<div><strong>Agora IOT</strong>&nbsp;<br /><span style={{ fontSize: '10pt' }}>Admin Console</span></div>
						</div>

						<div style={{ display: "flex", alignItems: 'center', alignContent: 'right', justifyContent: 'right', fontSize: '10pt', paddingRight: '64px' }}>
							<Dropdown value={currentThemeName} style={{ minWidth: '140px', marginRight: '32px' }}
								onOptionSelect={(e, d) => {
									const newTheme = availableThemes.find(athm => athm.Name === d.optionText)
									setCurrentTheme(newTheme?.theme || lightTheme)
									setCurrentLogo(newTheme?.logo || logoLight)
									setCurrentThemeName(d.optionText || "")
								}}>
								{availableThemes.map(thm => (<Option key={thm.Name} text={thm.Name} value={thm.Name}>{thm.Name}</Option>))}
							</Dropdown>
							<Avatar></Avatar>&nbsp;&nbsp;
							<div><strong>Oliver Sturrock</strong><br />oliver@sportworks.com</div>
						</div>
					</div>

					<Navigation navigateTo={navigateTo} />
					<div style={{ display: 'flex', alignItems: 'stretch', minHeight: '100vh', height: '100vh' }}>
						<div style={{ backgroundColor: currentTheme.colorNeutralBackground3, display: 'flex', alignItems: 'stretch', minHeight: '100vh', minWidth: '240px' }}>

							<br />

							<TabList vertical style={{ padding: '12px', display: 'flex', justifyItems: 'flex-end', alignContent: 'top', color: 'white', height: '100vh', minWidth: '200px' }} onTabSelect={onTabSelect}>
								<SpacedTab value="/" icon={<Home24Regular color={iconColor} />} text="Home" />
								<SpacedTab value="orgs" icon={<Organization24Regular color={iconColor} />} text="Organizations" />
								<SpacedTab value="users" icon={<People24Regular color={iconColor} />} text="Users" />
								<SpacedTab value="roles" icon={<DatabasePerson24Regular color={iconColor} />} text="Roles & Permissions" />
								<SpacedTab value="devices" icon={<DeviceMeetingRoom24Regular color={iconColor} />} text="Devices" />
								<SpacedTab value="devicemap" icon={<Map24Regular color={iconColor} />} text="Device Map" />
								<SpacedTab value="devicedata" icon={<ChartMultiple24Regular color={iconColor} />} text="Device Data Analysis" />
								<SpacedTab value="alarms" icon={<Alert24Regular color={iconColor} />} text="Device Alarms" />
								<SpacedTab value="devicetypes" icon={<DeviceEq24Regular color={iconColor} />} text="Device Types" />
								<SpacedTab value="devicecohorts" icon={<GroupList24Regular color={iconColor} />} text="Device Groups" />
								<SpacedTab value="locations" icon={<Location24Regular color={iconColor} />} text="Locations" />
								<SpacedTab value="apps" icon={<AppGeneric24Regular color={iconColor} />} text="Apps" />
								<SpacedTab value="plans" icon={<AppsList24Regular color={iconColor} />} text="Plans & Licenses" />
								<div style={{ flexGrow: 1 }} />
								<SpacedTab value="settings" icon={<Settings24Regular color={iconColor} />} text="Settings" />
							</TabList>

						</div>
						<div style={{ minHeight: '100%' }}>
							<Routes>
								<Route path="/" element={<HomeMenu />} />
								<Route path="/orgs" element={<OrgList />} />
								<Route path="/users" element={<UserList />} />
								<Route path="/devices" element={<DeviceList />} />
								<Route path="/devicemap" element={<DeviceMap />} />
								<Route path="/devicedata" element={<DeviceDataCharts />} />
								<Route path="/alarms" element={<DeviceAlarmList />} />
								<Route path="/devicetypes" element={<DeviceTypeList />} />
								<Route path="/devicegroups" element={<DeviceTypeList />} />
								<Route path="/plans" element={<PlanList />} />
								<Route path="/locations" element={<LocationList />} />
								<Route path="/apps" element={<AppList />} />
								<Route path="/settings" element={<Settings />} />
							</Routes>
						</div>
					</div>
				</GlobalContext.Provider>
			</BrowserRouter>
		</FluentProvider>
	);
}

export default App